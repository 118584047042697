
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import ConfirmStudentModal from "@/components/ConfirmStudentModal.vue";
import * as Api from "@/types/graphql";
import { State as StateStore } from "../store";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import {
  CourseStudentStatus,
  getStudentStatus,
  getStudentStatusColorClass,
} from "@/utils/courses";

@Component({
  components: { Card, Btn, Modal, ConfirmStudentModal },
})
export default class HandleCourseStudentStatus extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getEducationStudent;
  @Action insertEducationStudent;

  @Prop()
  course;

  newStatus = null;
  showStatusModal = null;
  statusModalKey = null;

  @Watch("educationStudent")
  setShowEducationStudent(val) {
    this.showEducationStudent = val != null;
  }
  courseStudentStatus = CourseStudentStatus;

  educationStudent = null;
  showEducationStudent = false;

  lessons = [];

  async mounted() {
    await this.getCurrentStatus();
  }

  async getCurrentStatus() {
    this.educationStudent = await this.getEducationStudent({
      courseGuid: this.course.course_guid,
      userGuid: this.user.guid,
    });
    this.showEducationStudent = true;
  }

  checkCourseStudentStatus(value) {
    if (this.educationStudent == null) return false;

    return this.educationStudent.course_student_status == value;
  }

  showUserStatusModal(newStatus) {
    this.newStatus = newStatus;
    this.statusModalKey = Math.random();
    this.showStatusModal = true;
  }

  async updateStatus(status) {
    if (status == CourseStudentStatus.waiting) {
      await this.requestJoinCourse();
    }
    await this.getCurrentStatus();
    this.showStatusModal = false;
  }

  async requestJoinCourse() {
    await this.insertEducationStudent({
      courseGuid: this.course.course_guid,
      userGuid: this.user.guid,
    });
  }

  getStatusColor(status) {
    return getStudentStatusColorClass(status as CourseStudentStatus);
  }
}
