
import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import Avatar from "@/components/Avatar.vue";
import { State as StateStore } from "../store";
import { State, Mutation } from "vuex-class";
import { Prop } from "vue-property-decorator";
import * as Api from "@/types/graphql";
import _ from "lodash";


@Component({
  components: {
    Modal,
    Avatar,
  },
  watch:{
    user(val, prev){
      this.userStatus = val.status;
      this.statusListToShow = _.filter(this.statusList, (status) => {
        return status.code.includes(val.role.role_id)
      })
    }
  }
})
export default class ChangeUserStatusModal extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  user: Api.User;
  status: Api.UserStatus = null;
  userStatus = null;
  loading = false;
  statusListToShow = []

  showChangingStatusUserModal = null;

  async updateStatus() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: require("@/graphql/setUserStatus.graphql"),
      variables: {
        guid: this.user.guid,
        role: this.user.role.guid,
        status: this.userStatus.guid,
      },
    });
    this.loading = false;
    if (res.data.changeStatusUser) {
      this.$emit('success', [this.user.guid],
        this.userStatus )
      this.showSuccess("Stato aggiornato");
      this.onClose();
    } else {
      this.showError("Errore cambio stato");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
