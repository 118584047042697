
import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import Avatar from "@/components/Avatar.vue";
import { State as StateStore } from "../store";
import { State, Mutation, Getter } from "vuex-class";
import { Prop } from "vue-property-decorator";
import * as Api from "@/types/graphql";
import { getSuspendedStatusCode, StatusCodes } from "@/utils/status";
import gql from "graphql-tag";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class MakeUserTeacherModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  user: Api.User = null;
  @Prop({ default: false })
  disableTeacher: boolean;

  loading = false;

  async makeUserTeacher() {
    this.loading = true;

    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $isTeacher: Int) {
          updateUser(guid: $guid, is_teacher: $isTeacher)
        }
      `,
      variables: {
        guid: this.user.guid,
        isTeacher: this.disableTeacher ? 0 : 1,
      },
    });
    if (res.data.updateUser) {
      if (this.disableTeacher) {
        this.showSuccess("Formatore disabilitiato");
      } else {
        this.showSuccess("Utente abilitiato come formatore");
      }
      this.$emit("success");
      this.onClose();
    } else {
      this.showError("Errore durante aggiornamento utente");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
