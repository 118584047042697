
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CourseLessons from "@/components/CourseLessons.vue";
import CourseOverview from "@/components/CourseOverview.vue";
import HandleCourseStudentStatus from "@/components/HandleCourseStudentStatus.vue";
import StudentsTable from "@/components/StudentsTable.vue";
import AddStudentsTable from "@/components/AddStudentsTable.vue";
import { formatDateFromApi } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import {
  CourseStudentStatus,
  CourseType,
  getStudentStatus,
  mapCourseFromApi,
} from "@/utils/courses";
import * as _ from 'lodash';
import { isAdmin } from "@/utils/roles";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Card,
    Btn,
    CourseOverview,
    CourseLessons,
    HandleCourseStudentStatus,
    StudentsTable,
    AddStudentsTable,
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class CourseDetailsView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getCourse;
  @Action getUsers;
  @Action getCourseStudents;
  @Action getEducationStudent;
  @Mutation setUsers;

  tabKeys = ["courseInfoTab", "courseLessonsTab"];

  course = null;
  mappedCourse = null;
  canJoinLessons = false;

  courseType = CourseType;

  componentKey = null;

  async mounted() {
    if (this.showStudentsTab()) {
      this.tabKeys.push("courseStudentsTab");
      this.tabKeys.push("addStudentsTab");
    }
    this.onChangeTab();
  }

  async fetchCourse() {
    this.course = await this.getCourse({
      courseGuid: this.$route.params.courseGuid,
    });
    this.mappedCourse = mapCourseFromApi(this.course);
  }

  async onChangeTab() {
    await this.fetchCourse();

    const tabKey = this.$route.query.tab;

    switch (this.tabKeys.indexOf(tabKey as string)) {
      case 0: {
        this.componentKey = Math.random();
        break;
      }
      case 1: {
        const educationStudent = await this.getEducationStudent({
          courseGuid: this.course.course_guid,
          userGuid: this.user.guid,
        });
        if (educationStudent) {
          this.canJoinLessons =
            educationStudent.course_student_status ==
            CourseStudentStatus.subscribed;
        } else this.canJoinLessons = false;
        break;
      }
      case 2: {
        await this.getCourseStudents({
          courseGuid: this.$route.params.courseGuid,
        });
        break;
      }
      case 3: {
        const allUsers = await this.getUsers({ return: true });
        const otherUsers = _.filter(allUsers, (user) => {
          return getStudentStatus(this.course, user.guid) == ""
        })
        this.setUsers(otherUsers)
        break;
      }
    }
  }

  showStudentsTab() {
    return isAdmin(this.user);
  }

  canEdit() {
    return isAdmin(this.user);
  }

  formatDate(date) {
    return formatDateFromApi(date, "dddd DD MMMM YYYY");
  }

  showBackButton() {
    return this.$route.query.back != "false";
  }

  goBack() {
    this.$router.go(-1);
  }
}
