
import AssociateReferentToUserModal from "@/components/AssociateReferentToUserModal.vue";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import ConfirmStudentModal from "@/components/ConfirmStudentModal.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import Modal from "@/components/Modal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { associateData } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import {
  checkUserStatus,
  isAdmin1Status,
  isAdmin2Status,
  isDeleted,
  isSuspended,
  isUserStatus,
  StatusCodes,
} from "../utils/status";
import {
  CourseStudentStatus,
  getCourseStudentStatusLabel,
} from "@/utils/courses";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    ConfirmStudentModal,

    UsersSelector,
    CompaniesSelector,
    AssociateReferentToUserModal,
  },
  watch: {
    users(val, prev) {
      this.search = "";
      this.usersShown = val;
    },
    search(val) {
      this.filterUsers();
    },
    filters: {
      handler(val) {
        this.filterUsers();
      },
      deep: true,
    },
  },
})
export default class StudentsTable extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @State((state: StateStore) => state.education.students) users;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedUsers;

  courseStudentStatus = CourseStudentStatus;

  loading = false;

  @Prop({ default: true })
  showSelect: boolean;
  @Prop({ default: true })
  showFilters: boolean;

  @Prop()
  course;

  usersShown = [];

  statusFilter: Api.UserStatus[] = [];
  studentStatusFilter: string[] = [];
  search = "";
  filters = {
    status: [],
    studentStatus: []
  };

  sendingEmailUser = null;
  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  userStatus = null;
  selectedUser = null;
  newStatus = null;
  showStatusModal = null;
  statusModalKey = null;

  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Stato Iscrizione",
      align: "start",
      sortable: false,
      value: "courseStatus",
    },
    {
      text: "Tipologia",
      align: "start",
      sortable: false,
      value: "status",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  mounted() {
    this.initFilters();
  }

  initFilters() {
    this.statusFilter.push(this.getStatus(StatusCodes.USER_DIP_AZ));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_REF_AZ));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_ACTIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_SLEEPY));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_PASSIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN1_ACTIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN2_RES_AREA));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN2_RES_SER));

    this.studentStatusFilter.push(CourseStudentStatus.waiting)
    this.studentStatusFilter.push(CourseStudentStatus.subscribed)
    this.studentStatusFilter.push(CourseStudentStatus.revoked)
    this.studentStatusFilter.push(CourseStudentStatus.denied)
    this.studentStatusFilter.push(CourseStudentStatus.abandoned)
    this.studentStatusFilter.push(CourseStudentStatus.completed)

    this.filters.status = _.map(this.statusFilter, "code");
    this.filters.studentStatus = this.studentStatusFilter;
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  filterUsers() {
    let textFilteredUsers = this.users;
    if (!!this.search && this.search.length > 0) {
      textFilteredUsers = this.users.filter((u) => {
        if (
          (u.name.toLowerCase() + " " + u.surname.toLowerCase()).includes(this.search.toLowerCase()) ||
          u.email.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    }

    const statusFilteredUsers = _.filter(this.users, (user) => {
      return checkUserStatus(user, this.filters.status);
    });

    const studentStatusFilteredUsers = _.filter(this.users, (user) => {
      return this.filters.studentStatus.includes(user.course_student_status);
    });

    this.usersShown = _.intersectionBy(
      textFilteredUsers,
      statusFilteredUsers,
      studentStatusFilteredUsers,
      "guid"
    );
  }

  getRolesFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }

  getStatusLabel(code) {
    return this.getStatus(code)?.des;
  }

  getRoleTypeLabel(user) {
    if (isSuspended(user.status?.code) || isDeleted(user.status?.code)) {
      if (checkUserRole(user, [RoleCodes.USER])) {
        return "Utente";
      }
      if (checkUserRole(user, [RoleCodes.ADMIN2])) {
        return "Amministratore Liv.2";
      }
    } else return user.status?.des;
  }

  getStudentstatusLabel(status) {
    return getCourseStudentStatusLabel(status);
  }

  setSending(item) {
    if (_.isNil(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailToUser(
          $guid: ID!
          $subject: String!
          $text: String!
        ) {
          sendEmailToUser(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: this.selected[0].guid,
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailToUser) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  showChangeStatusModal(user, status) {
    this.newStatus = status;
    this.selectedUser = user;
    this.statusModalKey = Math.random();
    this.showStatusModal = true;
  }

  updateSelectedUser(status) {
    if (status != null) {
      this.selectedUser["course_student_status"] = status;
    }
  }

  updateUsers() {
    this.$emit("update");
  }

  isSuspended(code) {
    return isSuspended(code);
  }

  isDeleted(code) {
    return isDeleted(code);
  }

  getStatusLabelForFiltering(status) {
    if (isUserStatus(status.code)) {
      return status.des + " (Utente)";
    }
    if (isAdmin1Status(status.code)) {
      return status.des + " (Admin Livello 1)";
    }
    if (isAdmin2Status(status.code)) {
      return status.des + " (Admin Livello 2)";
    }
  }
}
