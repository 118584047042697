
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import DeleteLessonModal from "@/components/DeleteLessonModal.vue";
import LessonItem from "@/components/LessonItem.vue";
import ManageLessonModal from "@/components/ManageLessonModal.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import copy from "clipboard-copy";
import gql from "graphql-tag";
import * as _ from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";


@Component({
  components: {
    Card,
    Btn,
    Modal,
    LessonItem,
    ManageLessonModal,
    DeleteLessonModal,
  },
})
export default class CourseLessons extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Mutation showSuccess;
  @Prop()
  lessonsData;

  modalKey = 12;
  lessonToEditIndex = -1;
  showManageLessonModal = null;
  lessonToEdit = null;

  showDeleteLessonModal = null;

  @Prop({
    default: () => {
      return [];
    },
  })
  teachers;

  @Prop({ default: false })
  canJoinLessons;

  @Prop()
  courseGuid;

  lessons = [];

  @Watch("lessonsData")
  handleCourseData(val) {
    this.lessons = val;
  }

  mounted() {
    this.lessons = this.lessonsData;
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  editLesson(lesson, index) {
    this.lessonToEdit = _.clone(lesson);
    this.lessonToEditIndex = index;
    this.modalKey = Math.random();
    this.showManageLessonModal = true;
  }

  deleteLesson(lesson, index) {
    this.lessonToEdit = _.clone(lesson);
    this.lessonToEditIndex = index;
    this.modalKey = Math.random();
    this.showDeleteLessonModal = true;
  }

  saveLesson(lesson) {
    if (this.lessonToEditIndex >= 0) {
      this.lessons[this.lessonToEditIndex] = _.clone(lesson);
    } else {
      this.lessons.push(_.clone(lesson));
    }
    this.lessonToEditIndex = -1;
    this.lessonToEdit = null;
  }

  showAddLessonModal() {
    this.lessonToEdit = null;
    this.lessonToEditIndex = -1;
    this.modalKey = Math.random();
    this.showManageLessonModal = true;
  }
}
