
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import SelectedCompanies from "@/components/SelectedCompanies.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import gql from "graphql-tag";
import _ from "lodash";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Modal,
    Avatar,
    CompaniesSelector,
    SelectedCompanies,
  },
  watch: {
    selectedUsers(val) {
      if (this.value) {
        this.users = val;
        if (!_.isEmpty(this.users)) {
          if (this.users[0].company != null) {
            this.setSelectedCompanies([this.users[0].company]);
          }
        }
      }
    },
  },
})
export default class AssociateCompanyToUserModal extends Vue {
  @State((state: StateStore) => state.data.selectedUsers) selectedUsers;
  @State((state: StateStore) => state.data.selectedCompanies) selectedCompanies;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedCompanies;
  @Mutation setSelectedCompanies;

  @Prop()
  value;

  loading = false;

  users = [];

  mounted() {
    this.users = this.selectedUsers;
  }

  async save() {
    if (this.users.length == 1 && this.selectedCompanies.length == 1) {
      this.loading = true;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation put($guid: ID!, $companyGuid: String) {
            updateUser(guid: $guid, company_guid: $companyGuid)
          }
        `,
        variables: {
          guid: this.users[0].guid,
          companyGuid: this.selectedCompanies[0].guid,
        },
      });
      this.loading = false;
      if (res.data.updateUser) {
        this.$emit("success");
        this.showSuccess("Azienda associata correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante associazione azienda");
      }
    }
  }

  onClose() {
    this.resetSelectedUsers();
    this.resetSelectedCompanies();
    this.$emit("close");
  }
}
