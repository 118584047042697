
import * as Api from "@/types/graphql";
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import gql from "graphql-tag";
import _ from "lodash";
import { Prop } from "vue-property-decorator";
import { RoleCodes } from "@/utils/roles";

@Component({
  components: {
    Modal,
    Avatar,
    UsersSelector,
    SelectedUsers,
  },
  watch: {
    selectedUsers(val) {
      if (this.value) {
        if (!_.isEmpty(val)) {
          this.selectedReferent = val[0];
        }
      }
    },
  },
})
export default class AssociateReferentToUserModal extends Vue {
  @State((state: StateStore) => state.data.selectedUsers) selectedUsers;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedCompanies;
  @Mutation setSelectedCompanies;

  @Prop()
  value;

  @Prop()
  user;
  @Prop({ default: false })
  onlyAdminLiv1: boolean;

  loading = false;

  selectedReferent = null;

  mounted() {
    if (!_.isEmpty(this.selectedUsers)) {
      this.selectedReferent = this.selectedUsers[0];
    }
  }

  getRolesFilter() {
    if (this.onlyAdminLiv1) {
      return [this.getRole(RoleCodes.ADMIN1).guid];
    } else {
      return [
        this.getRole(RoleCodes.ADMIN1).guid,
        this.getRole(RoleCodes.ADMIN2).guid,
      ];
    }
  }

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $referent: UserInput) {
          updateUser(guid: $guid, facilitator: $referent)
        }
      `,
      variables: {
        guid: this.user.guid,
        referent: { guid: this.selectedReferent.guid },
      },
    });
    this.loading = false;
    if (res.data.updateUser) {
      this.$emit("success");
      this.showSuccess("Referente CNA modificato correttamente");
      this.onClose();
    } else {
      this.showError("Errore durante modifica Referente CNA");
    }
  }

  onClose() {
    this.resetSelectedUsers();
    this.$emit("close");
  }
}
