
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import gql from "graphql-tag";
import _ from "lodash";
import { getToday } from "@/plugins/moment";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Modal,
    Avatar,
    DatePicker,
  },
  watch: {
    selectedUsers(val) {
      if (this.value) {
        this.users = val;
      }
    },
  },
})
export default class HandleUserAssociatingModal extends Vue {
  @State((state: StateStore) => state.data.selectedUsers) selectedUsers;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedUsers;

  @Prop()
  value;

  loading = false;

  users = [];
  associatedDate = null;

  mounted() {
    this.users = this.selectedUsers;
  }

  showAssociatedOrNot() {
    if (this.value) {
      if (this.selectedUsers.length == 1) {
        if (this.users[0].associated) {
          return "associato a CNA";
        } else {
          return "non associato a CNA";
        }
      }
      return "";
    }
  }

  showAssociatingAction() {
    if (this.value) {
      if (this.selectedUsers.length == 1) {
        if (this.selectedUsers[0].associated) {
          return "annullare l'associazione a CNA?";
        } else {
          return "associare l'utente a CNA?";
        }
      } else {
        return "";
      }
    }
  }

  showDatePicker() {
    if (this.value) {
      if (this.selectedUsers.length == 1) {
        return !this.selectedUsers[0]?.associated;
      } else {
        return false;
      }
    }
  }

  getPreselectedDate() {
    this.associatedDateSelected(getToday());
    return getToday();
  }

  associatedDateSelected(date) {
    this.associatedDate = date;
  }

  async save() {
    if (this.users.length == 1) {
      this.loading = true;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation put($guid: ID!, $associated: Int, $associatedDate: Date) {
            updateUser(
              guid: $guid
              associated: $associated
              associated_year: $associatedDate
            )
          }
        `,
        variables: {
          guid: this.users[0].guid,
          associated: this.users[0].associated ? 0 : 1,
          associatedDate: this.associatedDate ? this.associatedDate : "",
        },
      });
      this.loading = false;
      if (res.data.updateUser) {
        this.$emit("success");
        if (this.users[0].associated) {
          this.showSuccess("Associazione a CNA annullata");
        } else {
          this.showSuccess("Utente associato a CNA");
        }
        this.onClose();
      } else {
        this.showError("Errore durante gestione associazione a CNA");
      }
    }
  }

  onClose() {
    this.resetSelectedUsers();
    this.$emit("close");
  }
}
