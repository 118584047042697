
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";

@Component({
  components: {},
  watch: {
    selectedCompanies(val) {
      if (_.isUndefined(this.customList)) {
        this.initCompaniesList(val);
      }
    },
    customList(val) {
      this.items = val;
    },
  },
})
export default class SelectedCompanies extends Vue {
  @State((state: StateStore) => state.data.selectedCompanies)
  selectedCompanies: Api.Company[];
  @Mutation removeSelectedCompany;
  @Mutation resetSelectedCompanies;

  @Prop()
  customList: Api.Company[];
  @Prop({ default: true })
  enableDelete: boolean;

  items = [];

  initCompaniesList(companies: Api.Company[]) {
    this.items = companies;
  }

  mounted() {
    if (_.isUndefined(this.customList)) {
      this.initCompaniesList(this.selectedCompanies);
    } else {
      this.initCompaniesList(this.customList);
    }
  }

  beforeDestroy() {
    this.resetSelectedCompanies();
  }
}
