
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class DeleteLessonModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  lesson;
  loading = false;

  async confirm() {
    this.loading = true;
    const res = await this.$apollo.mutate({
        mutation: gql`
          mutation deleteEducationLesson($lessonGuid: ID!) {
            deleteEducationLesson(lesson_guid: $lessonGuid)
          }
        `,
        variables: {
          lessonGuid: this.lesson.lesson_guid,
        },
      });
    this.loading = false;
    if (res) {
      this.$emit("success");
      this.showSuccess('Lezione eliminata');
      this.onClose();
    } else {
      this.showError("Errore eliminazione lezione");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
