
import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import Avatar from "@/components/Avatar.vue";
import { State as StateStore } from "../store";
import { State, Mutation, Getter } from "vuex-class";
import { Prop } from "vue-property-decorator";
import * as Api from "@/types/graphql";
import { StatusCodes, getDeletedStatusCode } from "@/utils/status";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class DeleteUserModal extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  users: Api.User[];
  user: Api.User = null;
  userStatus: Api.UserStatus = null;

  loading = false;

  showChangingStatusUserModal = null;

  async deleteUser() {
    this.loading = true;
    if (this.users.length == 1) {
      this.user = this.users[0];
      this.userStatus = this.getStatus(getDeletedStatusCode(this.user.role.role_id));
      await this.updateStatus();
    } else {
      const suspendingGuids = this.users.map((item) => item.guid);
      const res = await this.$apollo.mutate({
        mutation: require("@/graphql/deleteUser.graphql"),
        variables: { guid: suspendingGuids },
      });
      this.loading = false;
      if (res.data.suspendUser) {
        this.$emit("success", suspendingGuids, this.getStatus(getDeletedStatusCode(this.user.role.role_id)));
        this.showSuccess("Stato aggiornato");
        this.onClose();
      } else {
        this.showError("Errore cambio stato");
      }
    }
  }

  async updateStatus() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: require("@/graphql/setUserStatus.graphql"),
      variables: {
        guid: this.user.guid,
        role: this.user.role.guid,
        status: this.userStatus.guid,
      },
    });
    this.loading = false;
    if (res.data.changeStatusUser) {
      this.$emit("success", [this.user.guid], this.userStatus);
      this.showSuccess("Stato aggiornato");
      this.onClose();
    } else {
      this.showError("Errore cambio stato");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
