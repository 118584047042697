
import AssociateCompanyToUserModal from "@/components/AssociateCompanyToUserModal.vue";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import ChangeUserStatusModal from "@/components/ChangeUserStatusModal.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import DeleteUserModal from "@/components/DeleteUserModal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import HandleUserAssociatingModal from "@/components/HandleUserAssociatingModal.vue";
import MakeUserTeacherModal from "@/components/MakeUserTeacherModal.vue";
import AssociateReferentToUserModal from "@/components/AssociateReferentToUserModal.vue";
import Modal from "@/components/Modal.vue";
import SuspendUserModal from "@/components/SuspendUserModal.vue";
import { RoutesNames } from "@/router";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { associateData, getReferentName } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import {
  checkUserStatus,
  isAdmin1Status,
  isAdmin2Status,
  isDeleted,
  isSuspended,
  isUserStatus,
  StatusCodes,
} from "../utils/status";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    CreateUserModal,
    ChangeUserStatusModal,
    SuspendUserModal,
    DeleteUserModal,
    AssociateCompanyToUserModal,
    HandleUserAssociatingModal,
    UsersSelector,
    CompaniesSelector,
    AssociateReferentToUserModal,
    MakeUserTeacherModal,
  },
  watch: {
    users(val, prev) {
      this.search = "";
      this.usersShown = val;
    },
    search(val) {
      this.filterUsers();
    },
    filters: {
      handler(val) {
        this.filterUsers();
      },
      deep: true,
    },
  },
})
export default class AddStudentsTable extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @State((state: StateStore) => state.data.users) users;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;

  @Prop()
  course;

  loading = false;
  modalLoading = false;

  usersShown = [];

  statusFilter: Api.UserStatus[] = [];
  associateFilter = associateData;
  search = "";
  filters = {
    status: [],
    referent: null,
    company: null,
    associated: [],
  };

  showConfirmModal = null;
  confirmModalKey = null;

  status = [];
  userStatus = null;
  changingStatusUser = null;
  showChangingStatusUserModal = null;

  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Referente",
      align: "start",
      sortable: false,
      value: "referent",
    },
    {
      text: "Azienda",
      align: "start",
      sortable: false,
      value: "company",
    },
    {
      text: "Tipologia",
      align: "start",
      sortable: false,
      value: "status",
    },
  ];
  selected = [];

  mounted() {
    this.initFilters();
  }

  initFilters() {
    this.statusFilter.push(this.getStatus(StatusCodes.USER_DIP_AZ));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_REF_AZ));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_ACTIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_SLEEPY));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_PASSIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN1_ACTIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN2_RES_AREA));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN2_RES_SER));

    this.filters.status = _.map(this.statusFilter, "code");
    this.filters.associated = _.map(this.associateFilter, "value");
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  filterUsers() {
    let textFilteredUsers = this.users;
    if (!!this.search && this.search.length > 0) {
      textFilteredUsers = this.users.filter((u) => {
        if (
          u.name.toLowerCase().includes(this.search.toLowerCase()) ||
          u.surname.toLowerCase().includes(this.search.toLowerCase()) ||
          u.email.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    }

    const statusFilteredUsers = _.filter(this.users, (user) => {
      return checkUserStatus(user, this.filters.status);
    });

    let referentFilteredUsers = this.users;
    if (!_.isNil(this.filters.referent)) {
      referentFilteredUsers = _.filter(this.users, (user) => {
        return user.facilitator?.guid == this.filters.referent.guid;
      });
    }

    let companyFilteredUsers = this.users;
    if (!_.isNil(this.filters.company)) {
      companyFilteredUsers = _.filter(this.users, (user) => {
        return user["company_guid"] == this.filters.company.guid;
      });
    }

    const associateFilteredUsers = _.filter(this.users, (user) => {
      return this.filters.associated.includes(user.associated);
    });

    this.usersShown = _.intersectionBy(
      textFilteredUsers,
      statusFilteredUsers,
      referentFilteredUsers,
      companyFilteredUsers,
      associateFilteredUsers,
      "guid"
    );
  }

  getRolesFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }

  hasReferentName(user) {
    return !_.isEmpty(getReferentName(user));
  }

  getReferentName(user) {
    if (this.hasReferentName(user)) {
      return getReferentName(user);
    }
    return "Nessun referente";
  }

  hasCompany(user) {
    return user.company != null;
  }

  getCompanyName(user) {
    if (this.hasCompany(user)) {
      return user.company.ragsoc;
    }
    return "Nessuna azienda collegata";
  }

  getStatusLabel(code) {
    return this.getStatus(code)?.des;
  }

  getRoleTypeLabel(user) {
    if (isSuspended(user.status?.code) || isDeleted(user.status?.code)) {
      if (checkUserRole(user, [RoleCodes.USER])) {
        return "Utente";
      }
      if (checkUserRole(user, [RoleCodes.ADMIN2])) {
        return "Amministratore Liv.2";
      }
    } else return user.status?.des;
  }

  handleClick(item) {
    if (_.find(this.selected, item)) {
      this.selected = this.selected.filter((u) => {
        return u.guid != item.guid;
      });
    } else {
      this.selected.push(item);
    }
  }

  initConfirmModal() {
    this.showConfirmModal = true;
    this.confirmModalKey = Math.random();
  }

  isSuspended(code) {
    return isSuspended(code);
  }

  isDeleted(code) {
    return isDeleted(code);
  }

  getStatusLabelForFiltering(status) {
    if (isUserStatus(status.code)) {
      return status.des + " (Utente)";
    }
    if (isAdmin1Status(status.code)) {
      return status.des + " (Admin Livello 1)";
    }
    if (isAdmin2Status(status.code)) {
      return status.des + " (Admin Livello 2)";
    }
  }

  async addStudents(){

    this.modalLoading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation subscribeEducationStudents(
          $courseGuid: String
          $studentGuids:[String]
        ) {
          subscribeEducationStudents(course_guid: $courseGuid, student_guid: $studentGuids)
        }
      `,
      variables: {
        courseGuid: this.course.course_guid,
        studentGuids: this.selected.map((student) => { return student.guid})
      },
    });
    this.modalLoading = false;

    if(res.data.subscribeEducationStudents){
      this.showConfirmModal = null;

      this.selected = [];

      this.showSuccess('Utenti iscritti al corso')
      this.$emit('update')
    } else {
      this.showError('Errore durante iscrizione al corso')
    }
  }
}
